import { BrowserRouter } from "react-router-dom";
import Router from "./routers/Router";
import { ReactNode, createContext, useState } from "react";

interface AuthContextProps {
  currentForm: string;
  switchForm: (form: string) => void;
}
export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);
const App: React.FC = () => {
  const [currentForm, setCurrentForm] = useState("login");

  const switchForm = (form: string) => {
    switch (form) {
      case "login":
        setCurrentForm("login");
        break;
      case "signup":
        setCurrentForm("signup");
        break;
      case "forgotPassword":
        setCurrentForm("forgotPassword");
        break;
      case "resetPassword":
        setCurrentForm("resetPassword");
        break;
      default:
        setCurrentForm("login");
    }
  };

  return (
    // <AuthContext.Provider value={{ currentForm, switchForm }}>
    //   <BrowserRouter>
    //     <Router />
    //   </BrowserRouter>
    // </AuthContext.Provider>
    <></>
  );
};

export default App;
